@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background: #F8F8F8;
    font-family: 'EB Garamond', serif;
    color: #0A0A0A;
}

@layer utilities {
    .root-paddings {
        padding-left: 5vw;
        padding-right: 5vw;
    }
    * ::before, ::after {
        --tw-shadow: 0 0 transparent;
    }
  }